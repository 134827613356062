import React, { createContext, useContext, useState, useEffect } from "react";

const LOCAL_STORAGE_KEY = 'ReactQueryOptions';

const defaultOptions = {
  staleTime: 1000 * 60 * 5,
};

// @ts-ignore
const StaleTimeContext = createContext();

// hook
export const useStaleTime = () => useContext(StaleTimeContext);

export const StaleTimeProvider = ({ children, defaultStaleTime = 1000 * 60 * 5 }) => {
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    const savedOptions = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedOptions) {
      try {
        const parsedOptions = JSON.parse(savedOptions);
        setOptions({
          ...defaultOptions,
          ...parsedOptions,
        });
      } catch (error) {
        console.error('Error parsing localStorage options:', error);
        setOptions(defaultOptions);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(options));
  }, [options]);

  const setStaleTime = (newStaleTime) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      staleTime: newStaleTime || defaultStaleTime,
    }));
  };

  return (
    <StaleTimeContext.Provider value={{ staleTime: options.staleTime, setStaleTime }}>
      {children}
    </StaleTimeContext.Provider>
  );
};
