/* eslint-disable */

import React from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import { browserHistory } from "react-router";
// @ts-ignore
import momentTz from "moment-timezone";
import moment, { defineLocale } from "moment";
import momentNl from "moment/locale/nl";
import { Tooltip, Icon } from "antd";
import NewTooltip from "components/UI/Tooltip";

import { Input, Select as AntSelect, Spin } from "antd";
import FormItem from "components/UI/FormItem";
import Select from "components/UI/Select";
import SearchSelect from "components/UI/SearchSelect";

/* Images of hours */
// @ts-ignore
import m_quiz_s from "assets/images/m_quiz_s.png";
// @ts-ignore
import m_quiz from "assets/images/m_quiz.png";
// @ts-ignore
import m_check_in_location from "assets/images/m_check_in_location.png";
// @ts-ignore
import m_check_in from "assets/images/m_check_in.png";
// @ts-ignore
import m_check_out from "assets/images/m_check_out.png";
// @ts-ignore
import m_declaration from "assets/images/m_declaration.png";
// @ts-ignore
import m_job_accepted from "assets/images/m_job_accepted.png";
// @ts-ignore
import user_check_in from "assets/images/user_check_in.png";
// @ts-ignore
import user_check_in_location from "assets/images/user_check_in_location.png";
// @ts-ignore
import user_check_out from "assets/images/user_check_out.png";
import { serverConfig } from "../config";

/* XLSX */
import XLSX from "xlsx";
import { FormattedMessage } from "react-intl";

/**
 * Extract parts of a locale text given a dangerousInnerHTML
 * @param {{
 * HTMLTag: string;
 *
 * }} props
 */
export function extractTextParts() {}

/**
 * @param {{
 * intl: any;
 * formItemStyle?: any;
 * inputStyle?: any;
 * floatingLabel?: boolean;
 * label: string,
 * field: string,
 * type: ("select" | "multiple" | "input" | "area" | "number" | "search-select" | "hidden"),
 * rules: ("required")[],
 * extraData: ({
 *  loading?: boolean,
 *  selectList?: any[],
 *  optionKey?: any,
 *  optionValue?: any,
 *  emptyMessageId?: string,
 *  request?: (query: string) => Promise<any> | AxiosPromise<any>,
 *  onChange?: () => void
 *  initialOptions?: any[];
 *  })
 * getFieldDecorator: any;
 * initialValues: any;
 * }} props
 */
export function renderFormItem({
  intl,
  formItemStyle,
  inputStyle,
  floatingLabel,
  label,
  field,
  type,
  rules = [],
  extraData,
  getFieldDecorator,
  initialValues = {},
}) {
  const showLabel = floatingLabel
    ? { label: intl.formatMessage({ id: label }), floatingLabel }
    : {};
  return (
    <FormItem
      isSelect={type === "select" || type === "search-select"}
      isMultiple={type === "multiple"}
      isNumber={type === "number"}
      className="explain-bottom"
      type="shadow"
      style={{
        ...formItemStyle,
        display: type === "hidden" ? "none" : undefined,
      }}
      colon={false}
      {...showLabel}
    >
      {getFieldDecorator(field, {
        initialValue: initialValues[field],
        rules: rules.reduce((acc, rule) => {
          if (rule === "required") {
            acc.push({
              required: true,
              message: intl.formatMessage({ id: "Common.required" }),
            });
          } else if (rule.includes("maxLength")) {
            const maxLengthArray = rule.split("=");
            const maxLength = maxLengthArray && maxLengthArray[1];
            maxLength &&
              acc.push({
                max: parseInt(maxLength),
                message: intl.formatMessage(
                  { id: "Common.inputMaxLength" },
                  { number: maxLength }
                ),
              });
          }
          return acc;
        }, []),
      })(renderInput())}
    </FormItem>
  );

  function renderInput() {
    switch (type) {
      case "select":
        return (
          <Select
            type="shadow"
            mode={type}
            style={inputStyle}
            placeholder={intl.formatMessage({ id: label })}
            dropdownRender={(menuNode) =>
              extraData.loading ? (
                <div className="search-select-spin">
                  <Spin size="small" />
                </div>
              ) : (
                menuNode
              )
            }
            onSelect={extraData.onChange}
          >
            {extraData.selectList.map((item) => {
              return (
                <AntSelect.Option key={item[extraData.optionKey]} item={item}>
                  {item[extraData.optionValue]}
                </AntSelect.Option>
              );
            })}
          </Select>
        );

      case "multiple":
        return (
          <Select
            type="shadow"
            mode={type}
            style={inputStyle}
            placeholder={intl.formatMessage({ id: label })}
            dropdownRender={(menuNode) =>
              extraData.loading ? (
                <div className="search-select-spin">
                  <Spin size="small" />
                </div>
              ) : (
                menuNode
              )
            }
          >
            {extraData.selectList.map((item) => {
              return (
                <AntSelect.Option key={item[extraData.optionKey]}>
                  {item[extraData.optionValue]}
                </AntSelect.Option>
              );
            })}
          </Select>
        );

      case "search-select":
        return (
          <SearchSelect
            iconHidden
            selectProps={{
              placeholder: intl.formatMessage({
                id: label,
              }),
            }}
            dataKey={extraData.optionValue}
            getRequest={extraData.request}
            onSelect={extraData.onChange}
            optionalOptions={extraData.initialOptions}
          />
        );

      case "area":
        return (
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: label,
            })}
          />
        );

      default:
        return (
          <Input
            style={inputStyle}
            placeholder={intl.formatMessage({
              id: label,
            })}
          />
        );
    }
  }
}

/**
 * Get contrast of a given hex color based on 50% method
 * https://24ways.org/2010/calculating-color-contrast/
 * @param {String} hexColor route
 */
export function getContrast50(hexColor) {
  return parseInt(hexColor, 16) > 0xffffff / 2 ? "black" : "white";
}

/**
 * Get contrast of a given hex color based on YIQ method
 * https://24ways.org/2010/calculating-color-contrast/
 * @param {String} hexColor route
 */
export function getContrastYIQ(hexColor) {
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

/**
 * Change route
 * @param {String} path route
 * @param {String|Object} query params
 * @param {{ state?: any }} [args]
 */
export function pushPath(path, query, { state } = {}) {
  const normalizedPath = normalizePath(path);

  if (browserHistory) {
    browserHistory.push({ query, state, pathname: normalizedPath });
  }

  /**
   * Normalizes the paths with a slash at the end
   * @param {string} path
   */
  function normalizePath(path) {
    const indexOfSearchPart = path.indexOf("?");
    const pathBase =
      indexOfSearchPart === -1 ? path : path.slice(0, indexOfSearchPart);
    const pathSearch =
      indexOfSearchPart === -1 ? "" : path.slice(indexOfSearchPart);

    return `${pathBase}${
      pathBase.charAt(pathBase.length - 1) === "/" ? "" : "/"
    }${pathSearch}`;
  }
}

/**
 * Read localStorage data
 */
export function getDataInStorage(key = "Fl-u-data", options = {}) {
  const { parse = false } = options;

  if (supportLocalStorage()) {
    return parse
      ? JSON.parse(localStorage.getItem(key))
      : localStorage.getItem(key);
  } else {
    return null;
  }
}

/**
 * Save data in localStorage
 * @param {Object} data Data to be saved in localStorage
 */
export function saveInLocalStorage(data, key = "Fl-u-data") {
  if (typeof data === "object") {
    data = JSON.stringify(data);
  }

  if (supportLocalStorage()) {
    localStorage.setItem(key, data);
  }
}

/**
 * Verify if the browser supports localStorage
 */
function supportLocalStorage() {
  if (typeof window === "object" && window.localStorage) {
    return true;
  } else {
    if (typeof window === "object") {
      alert(
        "You are using a very old version of your browser !, for the application to work correctly you should update it"
      );
    }
    return false;
  }
}

/**
 * Filters the data list
 * @param {Array} data all items
 * @param {Array} filters items to filter
 */
export function filterData(data, filters) {
  for (const filter of filters) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid === filter.uuid) {
        data.splice(i, 1);
        break;
      }
    }
  }

  return data;
}

export function nested(theObject, path, separator) {
  try {
    separator = separator || ".";

    return path
      .replace("[", separator)
      .replace("]", "")
      .split(separator)
      .reduce(function(obj, property) {
        return obj[property];
      }, theObject);
  } catch (err) {
    return undefined;
  }
}

/**
 * Creates the query string to filters
 * @param {Object} filters Filters fields
 * @param {{ prefix?: string }} options
 */
export function getFiltersString(filters, { prefix = "" } = {}) {
  let filtersString = "";

  for (const key in filters) {
    const filter = filters[key];

    if (filter.hasOwnProperty("key")) {
      if (filter.value) {
        filtersString += `&${prefix ? `${prefix}__` : ""}${
          filter.prefix ? filter.prefix : ""
        }${filter.key}${filter.suffix ? filter.suffix : ""}=${
          filter.exclude ? "-" : ""
        }${encodeURIComponent(filter.value)}`;
      }
    } else {
      if (filter.value) {
        filtersString += `&${
          filter.prefix ? filter.prefix : ""
        }${key}=${encodeURIComponent(filter.value)}`;
      }
    }
  }

  return filtersString;
}

/**
 * Filter failed requests of a Promise.all
 * @param {Array} results results of Promise.all
 */
export function correctResults(results) {
  const correctResult = [];

  for (const result of results) {
    // console.log("The result.status of the request was", result.status);

    if (result.status === 200 || result.status === 201) {
      correctResult.push(result.data.results);
    }
  }
  // console.log("Here are the correct Results", correctResult);

  return correctResult;
}

/**
 * Changes time value from UTC to the users local timezone or vice versa
 * @param {string} timezone can be "local" or "server"
 * @param {string} time is the time to be converted, should be in DD-MM-YYYY HH:mm format
 * @param {"date" | "time"} format can be "date" to return a string in DD-MM-YYYY HH:mm format
 *                        or "time" to return a string in HH:mm format
 * @returns {string} in DD-MM-YYYY HH:mm or HH:mm depending on @param format
 */
export function changeTzTo(timezone, time, format, { transform = false } = {}) {
  let newTime;

  if (!transform) {
    return returnFormat(time, format);
  }

  // Converting server time to local time
  if (timezone === "local") {
    // Creates a moment object with the UTC timezone and the "DD-MM-YYYY HH:mm" format
    const serverTime = momentTz.utc(time, "DD-MM-YYYY HH:mm");
    newTime = momentTz
      .utc(serverTime)
      .local()
      .format("DD-MM-YYYY HH:mm");
  } else {
    // Converting local time to server time
    // Gets user's timezone
    let localTimeZone = momentTz.tz.guess();
    if (localTimeZone === "America/Bogota") localTimeZone = "Europe/Amsterdam";
    // Creates a moment object with the user's timezone and the "DD-MM-YYYY HH:mm" format
    const localTime = momentTz(time, "DD-MM-YYYY HH:mm").tz(localTimeZone);
    // converts local time into UTC time
    newTime = localTime.utc().format("DD-MM-YYYY HH:mm");
  }
  // Returns the whole string or only the second half depending on @param format
  return returnFormat(newTime, format);

  /**
   * @param {string} time A valid date string in the format "DD-MM-YYYY HH:mm"
   * @param {"date" | "time"} format
   */
  function returnFormat(time, format) {
    return format === "date" ? time : time.split(" ")[1];
  }
}

/**
 * Returns the string with the current date
 * @param {string} format valid moment format e.g. "DD-MM-YYYY"
 */
export function getCurrentDateString(format = "DD-MM-YYYY") {
  return moment().format(format);
}

/**
 * Returns numbers of days between today and a given date
 * @param {String} date in format "DD-MM-YYYY HH:mm"
 */
export function getDaysLeft(date) {
  const current = moment().startOf("day");
  const given = moment(date, "DD-MM-YYYY HH:mm");
  const daysLeft = moment.duration(given.diff(current)).asDays();
  // console.log(
  //   `There are ${daysLeft} between ${date}/${given} and ${current}`
  // );
  return Math.abs(Math.floor(daysLeft));
}

/**
 * Returns full name
 * @param {String} firstName in format "DD-MM-YYYY HH:mm"
 * @param {String} prefix in format "DD-MM-YYYY HH:mm"
 * @param {String} lastName in format "DD-MM-YYYY HH:mm"
 */
export function getFullName(firstName, prefix, lastName, extra = "") {
  const prefixStr = prefix ? ` ${prefix}` : "";
  const lastNameStr = lastName ? ` ${lastName}` : "";
  const firstNameStr = firstName ? ` ${firstName}` : "";
  const fullName = `${firstNameStr}${prefixStr}${lastNameStr}${
    extra ? ` ${extra}` : ""
  }`;

  return fullName != "null" ? fullName : "";
}

/**
 * Returns a valid date for moment
 * @param {String} date in format DD-MM-YYYY HH:MM
 * @param {Boolean} whitTime return the time
 * @param {Boolean} getMoment return a moment object
 * @returns {String|import("moment").Moment}
 */
export function getValidDate(date, whitTime = true, getMoment = false) {
  let dateArray = date ? date.split(" ") : ["", ""];
  let time = dateArray[1];
  dateArray = dateArray[0].split("-");
  let newTime;
  if (whitTime) {
    newTime = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]} ${time}`;
  } else {
    newTime = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  }

  if (getMoment) {
    return moment(newTime);
  } else {
    return newTime;
  }
}

/**
 * Returns the corresponding occupancy icon
 * @param {Object} fullData job
 */
export function getJobIcon(fullData) {
  if (fullData.is_full) {
    return <i className="fas fa-check" />;
  } else {
    if (fullData.applied_users - fullData.accepted_users > 0) {
      return <i className="fas fa-user-plus primary" />;
    } else {
      return <i className="fas fa-user-minus action" />;
    }
  }
}

/**
 * Returns the difference in hours between two dates in DD/MM/YYYY HH:mm format
 * @param {Object} job job
 */
export function calcPlannedHours(job) {
  const difference = moment(job.end_date, "DD-MM-YYYY HH:mm").diff(
    moment(job.start_date, "DD-MM-YYYY HH:mm")
  );
  const duration = moment.duration(difference);
  const hours = duration.asHours();

  return hours;
}

/**
 * Returns the comments icon if there is at least one
 * Returns the clock icon if the user_hours_worked is not equal to the plannedHours
 * Returns the alert icon if is_exception is true
 * Returns the an icon with a X if the hour is canceled
 * Returns the archive icon if is_archived is true
 * @param {Object} hour hour object
 */
export function getHourIcons(
  hour,
  isWorkflow = true,
  useNewTooltip = false,
  disabledIcons = [],
  isClientUser
) {
  let areTheSameDates = true;
  if (!isWorkflow) {
    areTheSameDates =
      moment(hour.end_date, "DD-MM-YYYY HH:mm").diff(
        moment(hour.start_date, "DD-MM-YYYY HH:mm")
      ) ===
      moment(hour.job.end_date, "DD-MM-YYYY HH:mm").diff(
        moment(hour.job.start_date, "DD-MM-YYYY HH:mm")
      );
  }
  
  const commentIcon = !isClientUser ? (
    hour.comment_texts || hour.comments ? (
      useNewTooltip ? (
        <NewTooltip title={hour.comment_texts || hour.comments}>
          <div style={{ cursor: "pointer" }} className="single-icon">
            <span>
              <i className="fas fa-comment normal" />
            </span>
          </div>
        </NewTooltip>
      ) : (
        <Tooltip title={hour.comment_texts || hour.comments}>
          <div style={{ cursor: "pointer" }} className="single-icon">
            <span>
              <i className="fas fa-comment normal" />
            </span>
          </div>
        </Tooltip>
      )
    ) : (
      ""
    )): ("")

  const directPayIcon = hour.check_in_check_out.direct_pay ? (
    useNewTooltip ? (
      <NewTooltip
        title={<FormattedMessage id={"Sidebar.directPayDescription"} />}
      >
        <div style={{ cursor: "pointer" }} className="single-icon">
          <span>
            <i className="fas fa-euro-sign head normal" />
          </span>
        </div>
      </NewTooltip>
    ) : (
      <Tooltip title={<FormattedMessage id={"Sidebar.directPayDescription"} />}>
        <div style={{ cursor: "pointer" }} className="single-icon">
          <span>
            <i className="fas fa-euro-sign head normal" />
          </span>
        </div>
      </Tooltip>
    )
  ) : (
    ""
  );

  const clockIcon = areTheSameDates ? (
    ""
  ) : (
    <div className="single-icon">
      <span>
        <i className="fas fa-clock normal" />
      </span>
    </div>
  );

  const alertIcon = hour.is_exception ? (
    <div className="single-icon">
      <span>
        <i className="fas fa-exclamation-triangle danger" />
      </span>
    </div>
  ) : (
    ""
  );
  const archivedIcon = hour.is_archived ? (
    <div className="single-icon">
      <span>
        <i className="fas fa-archive danger" />
      </span>
    </div>
  ) : (
    ""
  );
  const cancelledIcon =
    hour.invalid_state != null ? (
      <div className="single-icon">
        <span>
          <i className="fas fa-times danger" />
        </span>
      </div>
    ) : (
      ""
    );
  const correctionIcon =
    hour.job &&
    hour.job.job_type &&
    (hour.job.job_type.spot_type === "no_steps_filled_spot" ||
      hour.job.job_type === "no_steps_filled_spot") ? (
      <div className="single-icon">
        <span>
          <i className="fas fa-clipboard-check normal" />
        </span>
      </div>
    ) : (
      ""
    );

  const iconsToRender = {
    commentIcon,
    clockIcon,
    alertIcon,
    cancelledIcon,
    archivedIcon,
    correctionIcon,
    directPayIcon,
  };
  const icons = (
    <div className="hour-icons">
      {disabledIcons
        ? Object.keys(iconsToRender).map((key) =>
            !disabledIcons.includes(key) ? iconsToRender[key] : null
          )
        : iconsToRender}
    </div>
  );

  return icons;
}

/**
 * Returns the hours in 3h30 format
 * @param {number} hour amount of hours
 * @param {{
 *  lang?: "nl" | "en";
 *  plainValues?: boolean;
 * }} options
 * @returns {string}
 */
export function parseHour(hour, options = { lang: "nl", plainValues: false }) {
  const absHour = Math.abs(hour);
  const h = Math.floor(absHour);
  let m = Math.floor((absHour - h) * 60);

  m = parseInt(`${m < 10 ? `0${m}` : m}`);
  m = ~~m;

  return options.plainValues
    ? `${parseInt(`${h < 10 ? `0${h}` : h}`)}:${m}`
    : `${h}${options.lang === "nl" ? "u" : "h"}${m}m`;
}

export function parseHourFormat(hour) {
  const absHour = Math.abs(hour);
  const h = Math.floor(absHour);
  let m = Math.round((absHour - h) * 60);

  return `${h}:${m < 10 ? `0${m}` : m}`;
}

/**
 * Returns the hours in 3h30 (arrow) (+0h30) format
 * @param {Object} hour hour object
 */
export function calcAndFormatHours(hour) {
  let workedHours;

  if (
    !hour ||
    !hour.job ||
    !hour.check_in_check_out ||
    !Array.isArray(hour.check_in_check_out) ||
    hour.check_in_check_out.length < 1
  )
    return;

  if (hour.end_date) {
    workedHours = calcPlannedHours(hour);
  } else {
    workedHours = calcPlannedHours(hour.job);
  }

  const plannedHours = calcPlannedHours(hour.job);
  const hoursDiff = workedHours - plannedHours;

  const arrowUp =
    hoursDiff > 0 ? (
      <div className="arrow-hour">
        <span>
          <i className="fas fa-arrow-up" />
        </span>
      </div>
    ) : (
      ""
    );
  const arrowDown =
    hoursDiff < 0 ? (
      <div className="arrow-hour">
        <span>
          <i className="fas fa-arrow-down" />
        </span>
      </div>
    ) : (
      ""
    );

  return (
    <div className="formated-hours">
      <div>{parseHour(hour.check_in_check_out[0].user_hours_worked)}</div>
      {arrowUp}
      {arrowDown}
      <div>{hoursDiff != 0 && `(${parseHour(hoursDiff)})`}</div>
    </div>
  );
}

/**
 * Return working hours
 */
export function getWorkingHours(checkInCheckOut) {
  if (
    !checkInCheckOut ||
    !Array.isArray(checkInCheckOut) ||
    checkInCheckOut.length < 1
  )
    return;
  if (!checkInCheckOut[0].user_hours_worked) return;

  let workingHours = checkInCheckOut[0].user_hours_worked.split(".");
  workingHours[0] = parseInt(workingHours[0]);
  if (workingHours[1]) {
    workingHours[1] = parseInt(workingHours[1]);
  }
  return workingHours;
}

/**
 * Returns the travel time in 2h00 arrow 1h00 format
 * @param {Object} hour hour object
 */
export function calcTravelTime(hour) {
  if (
    !hour.check_in_check_out ||
    !Array.isArray(hour.check_in_check_out) ||
    hour.check_in_check_out.length < 1 ||
    !hour.check_in_check_out[0].travel_time
  ) {
    return;
  }

  const travelTime = parseFloat(hour.check_in_check_out[0].travel_time);
  const travelTimeApplicable = parseFloat(
    hour.check_in_check_out[0].applicable_travel_time || 0
  );
  const realTravelTime =
    travelTime > travelTimeApplicable ? (
      <span>
        {" "}
        <i className="fas fa-chevron-up" />
        {` (${parseHour(travelTimeApplicable)})`}
      </span>
    ) : (
      <span>
        {" "}
        <i className="fas fa-chevron-down" />
        {` (${parseHour(travelTimeApplicable)})`}
      </span>
    );

  return (
    <div>
      {travelTime === travelTimeApplicable ? (
        `${parseHour(travelTime)}`
      ) : (
        <span>
          {parseHour(travelTime)}
          {travelTimeApplicable > 0 && realTravelTime}
        </span>
      )}
      {` € ${(hour.check_in_check_out[0].travel_time_fee &&
        hour.check_in_check_out[0].travel_time_fee.toFixed(2)) ||
        "0"}`}
    </div>
  );
}

/**
 * Returns the travel time in 2h00 arrow 1h00 format
 * @param {Object} hour hour object
 */
export function calcCompHours(hour) {
  if (!hour.check_in_check_out[0]) return <div>{"-"}</div>;

  const hours = hour.check_in_check_out[0].compensation_hours;
  const compHours =
    hours === "0.00" ? (
      "-"
    ) : (
      <span>
        <i className="fas fa-plus" />
        {parseHour(parseFloat(hours))}
      </span>
    );
  return <div>{compHours}</div>;
}

/**
 * Returns the camera icon with the color depending on other costs
 * @param {Object} checkInCheckOut
 * @param {String} which otherCost / transportationCost / both
 */
export function getExtraCost(
  checkInCheckOut,
  which,
  onClickPicture,
  disableCameraIcon = false
) {
  if (!checkInCheckOut) return "-";
  const otherCost = parseFloat(checkInCheckOut.other_cost);
  const transportationCost = parseFloat(checkInCheckOut.transportation_cost);

  let transportationPic = false;
  let otherCostPic = false;

  if (checkInCheckOut.images) {
    for (let i = 0; i < checkInCheckOut.images.length; i += 1) {
      const imageType = checkInCheckOut.images[i].image_type;
      if (imageType === "public_transport") transportationPic = true;
      if (imageType === "other_costs") otherCostPic = true;
    }
  }

  const isThereAPicture = transportationPic || otherCostPic;

  switch (which) {
    case "both":
      return (
        <div>
          {otherCost > 0 || transportationCost > 0 ? (
            <div>
              {otherCost > 0 ? `€${checkInCheckOut.other_cost} ` : ""}
              {transportationCost > 0
                ? `OV €${checkInCheckOut.transportation_cost} `
                : ""}
              {!disableCameraIcon && (
                <span
                  // @ts-ignore
                  onClick={(e) =>
                    onClickPicture &&
                    onClickPicture(
                      `${which}-${isThereAPicture ? "picture" : "noPicture"}`
                    )
                  }
                >
                  <i
                    style={{ cursor: isThereAPicture ? "cursor" : "default" }}
                    className={`fas fa-camera ${
                      isThereAPicture
                        ? "there-is-picture"
                        : "there-is-no-picture"
                    }`}
                  />
                </span>
              )}
            </div>
          ) : (
            "-"
          )}
        </div>
      );
    case "otherCost":
      return (
        <div>
          {otherCost > 0 ? (
            <div>
              {otherCost > 0 ? `€${checkInCheckOut.other_cost} ` : ""}
              {!disableCameraIcon && (
                <span
                  // @ts-ignore
                  onClick={(e) =>
                    onClickPicture(
                      `${which}-${isThereAPicture ? "picture" : "noPicture"}`
                    )
                  }
                >
                  <i
                    className={`fas fa-camera ${
                      otherCostPic ? "there-is-picture" : "there-is-no-picture"
                    }`}
                  />
                </span>
              )}
            </div>
          ) : (
            "-"
          )}
        </div>
      );
    case "transportationCost":
      return (
        <div>
          {transportationCost > 0 || transportationCost > 0 ? (
            <div>
              {transportationCost > 0
                ? `OV €${checkInCheckOut.transportation_cost} `
                : ""}
              <span
                // @ts-ignore
                onClick={(e) =>
                  onClickPicture(
                    `${which}-${isThereAPicture ? "picture" : "noPicture"}`
                  )
                }
              >
                <i
                  className={`fas fa-camera ${
                    transportationPic
                      ? "there-is-picture"
                      : "there-is-no-picture"
                  }`}
                />
              </span>
            </div>
          ) : (
            "-"
          )}
        </div>
      );
    default:
      break;
  }
}

/**
 * Returns the camera icon with the color depending on other costs
 * @param {{
 *  checkInCheckOut: Object;
 *  which: String;
 *  onClickPicture?: any;
 *  disableCameraIcon?: boolean;
 *  disableNoPicValidation?: boolean;
 * }} props
 */
export function getExtraCostSingles({
  checkInCheckOut,
  which,
  onClickPicture = null,
  disableCameraIcon = false,
  disableNoPicValidation = false,
}) {
  if (!checkInCheckOut) return "-";
  const otherCost = parseFloat(checkInCheckOut.other_cost);
  const transportationCost = parseFloat(checkInCheckOut.transportation_cost);

  let transportationPic = false;
  let otherCostPic = false;

  if (checkInCheckOut.images) {
    for (let i = 0; i < checkInCheckOut.images.length; i += 1) {
      const imageType = checkInCheckOut.images[i].image_type;
      if (imageType === "public_transport") transportationPic = true;
      if (imageType === "other_costs") otherCostPic = true;
    }
  }

  const isThereAPicture = transportationPic || otherCostPic;

  switch (which) {
    case "both":
      return (
        <div>
          {otherCost > 0 || transportationCost > 0 ? (
            <div>
              {otherCost > 0 ? `€${checkInCheckOut.other_cost} ` : ""}
              {transportationCost > 0
                ? `OV €${checkInCheckOut.transportation_cost} `
                : ""}
              {!disableCameraIcon && (
                <span
                  // @ts-ignore
                  onClick={(e) =>
                    onClickPicture &&
                    onClickPicture(
                      `${which}-${isThereAPicture ? "picture" : "noPicture"}`
                    )
                  }
                >
                  <i
                    style={{ cursor: isThereAPicture ? "cursor" : "default" }}
                    className={`fas fa-camera ${
                      isThereAPicture
                        ? "there-is-picture"
                        : "there-is-no-picture"
                    }`}
                  />
                </span>
              )}
            </div>
          ) : (
            "-"
          )}
        </div>
      );
    case "otherCost":
      return (
        <div>
          {otherCost > 0 ? (
            <div>
              {otherCost > 0 ? `€${checkInCheckOut.other_cost} ` : ""}
              {!disableCameraIcon && (
                <span
                  // @ts-ignore
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disableNoPicValidation && !otherCostPic) return;
                    return onClickPicture(
                      `${which}-${isThereAPicture ? "picture" : "noPicture"}`
                    );
                  }}
                >
                  <i
                    className={`fas fa-image ${
                      otherCostPic ? "there-is-picture" : "there-is-no-picture"
                    }`}
                  />
                </span>
              )}
            </div>
          ) : (
            "-"
          )}
        </div>
      );
    case "transportationCost":
      return (
        <div>
          {transportationCost > 0 || transportationCost > 0 ? (
            <div>
              {transportationCost > 0
                ? `OV €${checkInCheckOut.transportation_cost} `
                : ""}
              <span
                // @ts-ignore
                onClick={(e) => {
                  e.stopPropagation();
                  if (!disableNoPicValidation && !transportationPic) return;
                  return onClickPicture(
                    `${which}-${isThereAPicture ? "picture" : "noPicture"}`
                  );
                }}
              >
                <i
                  className={`fas fa-image ${
                    transportationPic
                      ? "there-is-picture"
                      : "there-is-no-picture"
                  }`}
                />
              </span>
            </div>
          ) : (
            "-"
          )}
        </div>
      );
    default:
      break;
  }
}

const getIcon = (fullData) => {
  if (fullData.is_full) {
    return <i className="fas fa-check" />;
  } else {
    if (fullData.applied_users - fullData.accepted_users > 0) {
      return <i className="fas fa-user-plus" />;
    } else {
      return <i className="fas fa-user-minus" />;
    }
  }
};

/**
 * Returns the occupation of a job in (icon) accepted / needed - applied format
 * @param {Object} job
 */
export function showOccupation(job) {
  let appliedUsers = job.applied_users ? (
    <span>
      {` - ${job.applied_users}`} <Icon type="plus-circle" />
    </span>
  ) : (
    ""
  );
  let invitedUsers = job.invited_users ? (
    <span>
      {` - ${job.invited_users}`} <i className="fas fa-envelope" />
    </span>
  ) : (
    ""
  );
  return (
    <div className="job-occupation">
      {getIcon(job)} ({job.accepted_users}/{job.number_of_employees})
      {appliedUsers}
      {invitedUsers}
    </div>
  );
}

/**
 * Returns the appointment string in With (name) at (street, city) on (date) @ (time)
 * @param {Object} appointment
 */
export function getIntakeStr(appointment) {
  const person = appointment.host == "" ? false : appointment.host;
  const street = appointment.street == "" ? false : appointment.street;
  const city = appointment.city == "" ? false : appointment.city;
  const date =
    appointment.appointment_datetime == null
      ? false
      : appointment.appointment_datetime;

  const personStr = person ? `with ${person}` : "";

  const streetStr = street ? `${street}` : "";
  const cityStr = city ? `${city}` : "";
  const placeStr =
    street || city
      ? `at ${streetStr}${street && city ? ", " : ""}${cityStr}`
      : "";

  const dateStr = date
    ? `on ${date.split(" ")[0]} @ ${changeTzTo("local", date, "time")}`
    : "";

  const intake = `${personStr}${person ? ` ${placeStr}` : placeStr}${
    person || placeStr ? ` ${dateStr}` : dateStr
  }`;

  return intake;
}

/**
 * Returns the time stamp for the statuses that have them
 * @param {Object} checkInCheckOut
 * @param {string} status
 */
export function getSecondLabel(checkInCheckOut, status) {
  if (!checkInCheckOut) return "";

  switch (status) {
    case "check_in":
      return checkInCheckOut.check_in1
        ? changeTzTo("local", checkInCheckOut.check_in1, "time")
        : "";
      // @ts-ignore
      break;
    case "check_in_location":
      return checkInCheckOut.check_in2
        ? changeTzTo("local", checkInCheckOut.check_in2, "time")
        : "";
      // @ts-ignore
      break;
    case "check_out":
      return checkInCheckOut.check_out
        ? changeTzTo("local", checkInCheckOut.check_out, "time")
        : "";
      // @ts-ignore
      break;
    default:
      break;
  }
}

/**
 * It's an object with the statuses considered for the WorkFlowBig
 */
export const statusProps = {
  quiz_complete: {
    iClass: "pencil-alt",
    labelText: "EmployeeList.quiz",
    secondLabel: false,
  },
  check_in: {
    iClass: "user-clock",
    labelText: "EmployeeList.checkedIn",
    secondLabel: true,
  },
  check_in_location: {
    iClass: "map-marker-alt",
    labelText: "EmployeeList.atLocation",
    secondLabel: true,
  },
  upload_photo: {
    iClass: "camera",
    labelText: "EmployeeList.areTaken",
    secondLabel: false,
  },
  check_out: {
    iClass: "user-slash",
    labelText: "EmployeeList.checkOut",
    secondLabel: true,
  },
  evaluation: {
    iClass: "edit",
    labelText: "EmployeeList.evaluation",
    secondLabel: false,
  },
  hour_declaration: {
    iClass: "euro-sign",
    labelText: "EmployeeList.areSent",
    secondLabel: false,
  },
  check_in_qr: {
    iClass: "qrcode",
    labelText: "CreateWorkflowTemplate.check_in_qr",
    secondLabel: false,
  },
  check_out_qr: {
    iClass: "qrcode",
    labelText: "CreateWorkflowTemplate.check_out_qr",
    secondLabel: false,
  },
  item_distribution: {
    iClass: "qrcode",
    labelText: "CreateWorkflowTemplate.assignMaterials",
    secondLabel: false,
  },
  item_gathering: {
    iClass: "qrcode",
    labelText: "Gather materials",
    secondLabel: false,
  },
  signing_off: {
    iClass: "signature",
    labelText: "CreateWorkflowTemplate.manager_sign_off",
    secondLabel: false,
  },
  employee_signing_off: {
    iClass: "user-edit",
    labelText: "CreateWorkflowTemplate.employee_signing_off",
    secondLabel: false,
  }
};

/**
 * It's an array with the statuses considered for the WorkFlowBig
 */
export const consideredStatuses = [
  {
    key: "job_accepted",
    name: "Shift.jobAccepted",
    iClass: "user-check",
    labelText: "EmployeeList.jobAccepted",
    secondLabel: false,
  },
  {
    key: "quiz_complete",
    name: "Shift.quizComplete",
    iClass: "pencil-alt",
    labelText: "EmployeeList.quiz",
    secondLabel: false,
  },
  {
    key: "check_in",
    name: "Shift.checkIn",
    iClass: "user-clock",
    labelText: "EmployeeList.checkedIn",
    secondLabel: true,
  },
  {
    key: "check_in_qr",
    name: "CreateWorkflowTemplate.check_in_qr",
    iClass: "qrcode",
    labelText: "CreateWorkflowTemplate.check_in_qr",
    secondLabel: false,
  },
  {
    key: "check_in_location",
    name: "Shift.checkInLocation",
    iClass: "map-marker-alt",
    labelText: "EmployeeList.atLocation",
    secondLabel: true,
  },
  {
    key: "upload_photo",
    name: "Shift.uploadPhoto",
    iClass: "camera",
    labelText: "EmployeeList.areTaken",
    secondLabel: false,
  },
  {
    key: "check_out",
    name: "Shift.checkOut",
    iClass: "user-slash",
    labelText: "EmployeeList.checkOut",
    secondLabel: true,
  },
  {
    key: "check_out_qr",
    name: "CreateWorkflowTemplate.check_out_qr",
    iClass: "qrcode",
    labelText: "CreateWorkflowTemplate.check_out_qr",
    secondLabel: false,
  },
  {
    key: "evaluation",
    name: "Shift.evaluation",
    iClass: "edit",
    labelText: "EmployeeList.evaluation",
    secondLabel: false,
  },
  {
    key: "hour_declaration",
    name: "Shift.hourDeclaration",
    iClass: "euro-sign",
    labelText: "EmployeeList.areSent",
    secondLabel: false,
  },
];

/**
 * It's an array with the invalid statuses
 */
export const invalidStatuses = [
  {
    key: "sick",
    name: "Common.invalidStates.sick",
  },
  {
    key: "no_show",
    name: "Common.invalidStates.no_show",
  },
];

/**
 * Handles the all selection option
 * @param {Array} newValues
 * @param {Array} prevValues
 * @param {Array} options
 * @param {string} optionsKey
 * @param {string} allOptionValue
 */
export const handleAllSelectOption = (
  newValues,
  prevValues = [],
  options = [],
  optionsKey,
  allOptionValue,
  clearOptionValue = null
) => {
  // If "all" option is selected.
  if (
    newValues.includes(allOptionValue) &&
    !prevValues.includes(allOptionValue)
  ) {
    return [allOptionValue, ...options.map((option) => option[optionsKey])];
  }

  // If "all" option is unselected.
  if (
    !newValues.includes(allOptionValue) &&
    prevValues.includes(allOptionValue)
  ) {
    return [];
  }

  // If "clear" option is selected.
  if (
    clearOptionValue &&
    newValues.includes(clearOptionValue) &&
    !prevValues.includes(clearOptionValue)
  ) {
    return [clearOptionValue];
  }

  // If another options is selected and "clear" option was previously selected.
  if (
    clearOptionValue &&
    newValues.includes(clearOptionValue) &&
    prevValues.includes(clearOptionValue)
  ) {
    const newValuesWithNoClearOption = newValues.slice();
    newValuesWithNoClearOption.splice(
      newValuesWithNoClearOption.indexOf(clearOptionValue),
      1
    );
    return newValuesWithNoClearOption;
  }

  // If "all" option was previously selected and one option is unselected.
  if (
    prevValues.includes(allOptionValue) &&
    newValues.length !== prevValues.length
  ) {
    return newValues.filter((value) => value !== allOptionValue);
  }

  // If all the options are selected individually.
  if (
    !prevValues.includes(allOptionValue) &&
    newValues.length === options.length
  ) {
    return [allOptionValue, ...options.map((option) => option[optionsKey])];
  }

  return newValues;
};

/**
 * Returns a float with 2 decimal points representing hours from a moment object
 * @param {string} type
 * @param {import("moment").Moment|Number} value
 */
export const roundToTwo = (type, value) => {
  let num =
    type == "time"
      ? parseFloat(moment(value).format("H")) +
        parseFloat(`${parseInt(moment(value).format("mm")) / 60}`)
      : value;

  return typeof num === "number" ? num.toFixed(2) : num;
};

export const hourUpdateFields = [
  "user_hours_worked",
  "travel_time",
  "travel_distance",
  "transportation_cost",
  "other_cost",
  "current_latitude",
  "current_longitude",
  "compensation_hours",
  "start_date",
  "end_date",
  "break_hours",
  "active_hourly_rate",
];

export const calcHoursWorked = (start, end) => {
  var duration = moment.duration(moment(end).diff(moment(start)));
  var hours = roundToTwo("time", moment.utc(duration.asMilliseconds()));

  return hours;
};

/**
 * Check if this scroll it down enough to make a new request
 * @param {String} containerId element id
 * @param {{top?: boolean; currentTarget?: HTMLElement}} options
 */
export function shouldFetch(containerId, options = {}) {
  const tableElement =
    options.currentTarget || document.getElementById(containerId);
  const currentPosition = tableElement.scrollTop + tableElement.offsetHeight;
  const totalHeightOfTable = tableElement.scrollHeight;
  const percentageScrolled = currentPosition / totalHeightOfTable;

  return options.top
    ? tableElement.scrollTop < 10
    : !!percentageScrolled && percentageScrolled > 0.8;
}

/**
 * Builds lists of categories for projects
 * @param {Object} pool
 * @param {Array} categories
 */
export function buildProjectCategories(pool, categories) {
  if (pool.job_categories) {
    let categoriesStr = pool.job_categories;
    let projectCats = categoriesStr.split("&");
    let categoryTypes = {
      regular: false,
      trainings: false,
      qualifications: false,
    };
    for (const key in categoryTypes) {
      let cat = categories[key];
      for (const item of cat) {
        if (projectCats.indexOf(item.uuid) != -1) {
          if (!categoryTypes[key]) {
            categoryTypes[key] = [];
          }
          categoryTypes[key].push(item);
        }
      }
    }
    return {
      branchList: categoryTypes.regular,
      trainingsList: categoryTypes.trainings,
      qualificationsList: categoryTypes.qualifications,
    };
  }
}

/**
 * Return if a object is empty
 * @param {object} obj
 * @returns {boolean}
 */
export function isEmpty(obj) {
  // @ts-ignore
  for (const key in obj) {
    return false;
  }

  return true;
}

/**
 * Remove blank attributes from an object: A blank attribute is an attribute whose value is false for Javascript
 * @param {object} object
 * @param {{
 *  level?: number;
 * }} props
 * @returns {object}
 */
export function removeBlankAttributesFromObject(object, { level = 0 } = {}) {
  return Object.entries(object)
    .filter(([_, value]) => !!value)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: level === 0 ? value : value,
      }),
      {}
    );
}

/**
 * Returns the correct img acording to the hours status
 * @param {String} state
 */
export function getHoursIcon(state) {
  switch (state) {
    case "check_in_location":
      return m_check_in_location;

    case "check_in":
      return m_check_in;

    case "check_out":
      return m_check_out;

    case "declaration":
      return m_declaration;

    case "job_accepted":
      return m_job_accepted;

    case "quiz":
      return m_quiz;

    case "quiz_s":
      return m_quiz_s;

    case "check_in_1":
      return user_check_in;

    case "check_in_2":
      return user_check_in_location;

    case "user_check_out":
      return user_check_out;

    case "hour_declaration":
      return m_declaration;

    default:
      return m_check_in;
  }
}

/**
 * Opens a new tab
 * @param {String} url
 */
export function openNewTab(url) {
  window.open(url, "Fleks_newTab");
}

/**
 * Return the string to calmel case
 * @param {String} str
 */
export function toCamelCase(str, options = {}) {
  return (
    str
      .split(" ")
      // @ts-ignore
      .map(function(word, index) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(options.joinChar || "")
  );
}

/**
 * Return a category filtered by uuid
 * @param {Array} categories
 * @param {String} uuid
 */
export function getJobCategoryByUuid(categories, uuid) {
  for (const key in categories) {
    for (let i = 0; i < categories[key].length; i++) {
      if (categories[key][i].uuid === uuid) {
        return categories[key][i];
      }
    }
  }
}

/**
 * Makes promise cancelable
 * @param {Promise<any> | import("axios").AxiosPromise} promise
 */
export function makeCancelable(promise) {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
}

/**
 * Extracts the filters of location to apply it
 * @param {Object} location
 * @param {Object} filters
 * @returns {Object} filtersToApply
 */
export function extractFilters(location, filters) {
  const filtersList =
    (location.query.filters && location.query.filters.split(",")) || [];
  const filterToApply = {};

  filtersList.forEach((filter) => {
    const [name, value] = filter.split(":");

    if (filters[name]) {
      filterToApply[name] = filters[name];

      filterToApply[name].value = value;
    }
  });

  return filterToApply;
}

/**
 * Builds the url image
 * @param {String} resourceUrl
 * @param {RegExp} regExp
 * @return {String}
 */
export function buildResourceUrl(
  resourceUrl,
  regExp = /(s3-|s3\.)?(.*)\.amazonaws\.com/
) {
  const HTTP_REG_EXP = /(http(s?)):\/\//g;

  if (typeof resourceUrl !== "string") return "";

  return resourceUrl.match(regExp) ||
    resourceUrl.match(HTTP_REG_EXP) ||
    resourceUrl.startsWith("data:image")
    ? resourceUrl
    : `${serverConfig.apiUrl}/api${resourceUrl}`;
}

/**
 * Builds a new array with values from min number to maz number
 * @param {number} minValue
 * @param {number} maxValue
 */
export function range(minValue, maxValue) {
  const result = [];

  for (let i = minValue; i < maxValue; i++) {
    result.push(i);
  }

  return result;
}

/**
 * @param {string} amount
 * @returns {string}
 */
export function fixAmountWithDots(amount) {
  const indexFirstDot = `${amount}`.indexOf(".");
  const indexFirstComma = `${amount}`.indexOf(",");
  let integerPart = "";
  let decimalPart = "";

  amount = `${amount}`;

  if (indexFirstComma === -1 && indexFirstDot === -1)
    return amount.replace(/[A-Za-z]|\W/g, "");

  if (
    (indexFirstComma !== -1 && indexFirstComma < indexFirstDot) ||
    (indexFirstComma !== -1 && indexFirstDot === -1)
  ) {
    integerPart = amount.substring(0, indexFirstComma);
    decimalPart = amount.substring(indexFirstComma + 1);
  }

  if (
    (indexFirstDot !== -1 && indexFirstDot < indexFirstComma) ||
    (indexFirstDot !== -1 && indexFirstComma === -1)
  ) {
    integerPart = amount.substring(0, indexFirstDot);
    decimalPart = amount.substring(indexFirstDot + 1);
  }

  return `${integerPart
    .replace(",", ".")
    .replace(/[A-Za-z]|\W/g, "")}.${decimalPart.replace(
    /[A-Za-z]|\.|\,|\W/g,
    ""
  )}`;
}

/**
 * Creates a string for columns and columns header to export
 * @param {{
 *  custom: {
 *    code: string;
 *    name: string;
 *  }[]
 * }} selectedOptions selected modal export options
 * @returns {{
 *  columns: string;
 *  columnsHeaders: string;
 * }}
 */
export function parseExportColumns(selectedOptions) {
  const columnsCustom = selectedOptions.custom;
  let columns = "";
  let columnsHeaders = "";

  if (Array.isArray(columnsCustom)) {
    columnsCustom.forEach((column, i) => {
      if (!column) return "";

      columns += `${column.code}${i < columnsCustom.length - 1 ? "," : ""}`;
      columnsHeaders += `${column.name}${
        i < columnsCustom.length - 1 ? "," : ""
      }`;
    });
  }

  return { columns, columnsHeaders };
}

/**
 * Calculates the color, text and icon according to hour status
 * @param {{
 *  is_approved: boolean;
 *  approved_at: any;
 *  is_exported: boolean;
 *  exported_at: any;
 * }} hour
 * @param intl the prop of react-intl
 */
export function getApprovedStatusProps(hour, intl) {
  let icon = "";
  let color = "";
  let text = "";

  if (
    (hour.is_approved == false || !!!hour.approved_at) &&
    (hour.is_exported == false || !!!hour.exported_at)
  ) {
    icon = "check";
    color = "orange";
    text = intl.formatMessage({ id: "Hours.needCheckAndPlan" });
  }

  if (
    (hour.is_approved == true || !!hour.approved_at) &&
    (hour.is_exported == false || !!!hour.exported_at)
  ) {
    icon = "check";
    color = "green";
    text = intl.formatMessage({ id: "Hours.checkedAndNeedPlan" });
  }

  if (
    (hour.is_approved == true || !!hour.approved_at) &&
    (hour.is_exported == true || !!hour.exported_at)
  ) {
    icon = "check-double";
    color = "green";
    text = intl.formatMessage({ id: "Hours.checkedAndPlanned" });
  }

  return { icon, color, text };
}

/**
 * @param {object[]} array
 * @param {string} key
 * @returns {object[]}
 */
export function removeDuplicated(array, key = "uuid") {
  const itemsObject = {};

  array.forEach((item) => {
    itemsObject[item[key]] = item;
  });

  return Object.keys(itemsObject).map((itemKey) => itemsObject[itemKey]);
}

/**
 * @param {object[]|object} contracts
 */
export function getWeeklyHoursInContract(contracts = []) {
  if (typeof contracts !== "object") return 0;

  if (!Array.isArray(contracts)) {
    contracts = [contracts];
  }

  return contracts.reduce((acc, contract) => {
    if (!!contract.start_date && !contract.end_date) {
      return contract.contract_hours;
    }

    return moment(contract.end_date, "DD-MM-YYYY").isAfter(
      moment(contract.start_date, "DD-MM-YYYY")
    ) && contract.is_signed
      ? contract.contract_hours
      : acc;
  }, 0);
}

/**
 * Extracts the initials of a text
 * @param {string} txt
 */
export function getInitials(txt) {
  const words = txt?.split(" ") || [];

  return words.reduce((acc, current, index) => {
    if (index === 1 && current.length < 4) {
      return acc;
    }

    return `${acc}${current.charAt(0)}`;
  }, "");
}

/**
 * @param {{ name: string; }[]} clients
 */
export function getClientsText(clients) {
  const [client, department] = clients;

  return `${department ? `${department.name} - ` : ""}${client && client.name}`;
}

/**
 * Loads the images for the gallery
 * @param {import("axios").AxiosResponse} response to add in the list
 */
export function loadInfoToGallery(response) {
  const galleryMedia = [];
  let countMediaImage = 0;

  for (const item of response.data.results) {
    const oneGallery = {};
    let containsImages = false;

    if (item.check_in_check_out && Array.isArray(item.check_in_check_out)) {
      for (const itCheckInOut of item.check_in_check_out) {
        if (
          itCheckInOut.images &&
          Array.isArray(itCheckInOut.images) &&
          itCheckInOut.images.length > 0
        ) {
          oneGallery.images = itCheckInOut.images;
          containsImages = true;
        }
      }

      oneGallery.job = item.job;
      oneGallery.project = item.project;
      oneGallery.brands = item.project ? item.project.brands : item.job.brands;
      oneGallery.employee = item.employee;
      oneGallery.start_date = item.start_date;
      oneGallery.end_date = item.end_date;

      if (containsImages) {
        countMediaImage += oneGallery.images.length;
        galleryMedia.push(oneGallery);
      }

      containsImages = false;
    }
  }

  return {
    countMediaImage,
    media: galleryMedia,
  };
}

export function validateNewUser() {
  const user = useSelector((state) => state.user);
  const validationDate = moment("01-06-2020", "DD-MM-YYYY");
  const tenantDateCreation =
    user.data && moment(user.data.tenant.date_created, "DD-MM-YYYY");
  return tenantDateCreation && tenantDateCreation.isAfter(validationDate);
}

/**
 * Creates the list of moments for one week
 * @param {import("moment").Moment} date
 */
export function calculateWeekDays(date) {
  const weekDays = [
    date.clone().startOf("isoWeek"),
    date
      .clone()
      .startOf("isoWeek")
      .add(1, "days"),
    date
      .clone()
      .startOf("isoWeek")
      .add(2, "days"),
    date
      .clone()
      .startOf("isoWeek")
      .add(3, "days"),
    date
      .clone()
      .startOf("isoWeek")
      .add(4, "days"),
    date
      .clone()
      .startOf("isoWeek")
      .add(5, "days"),
    date
      .clone()
      .startOf("isoWeek")
      .add(6, "days"),
  ];

  return weekDays;
}

/**
 * Calculate whether a job is night shift
 * @param {string} start Start date with the format DD-MM-YYYY HH:mm
 * @param {string} end End date with the format DD-MM-YYYY HH:mm
 * @returns {boolean}
 */
export function isANightShift(start, end) {
  return moment(end, "DD-MM-YYYY HH:mm").isAfter(
    moment(start, "DD-MM-YYYY HH:mm"),
    "day"
  );
}

/**
 * Adds the ellipsis points when the string is greater than max length
 * @param {string} txt
 * @param {number} n
 */
export function getEllipsisAt(txt, n) {
  if (txt.length <= n) return txt;

  return `${txt.slice(0, n)}...`;
}

/**
 * Returns a set of empty filters
 * @param {array} filters Filters that need to be cleared
 * @returns {object}
 */
export function getClearedFilters(filters) {
  const clearedFilters = {};
  filters.map((filter) => {
    clearedFilters[filter] = "";
  });
  return clearedFilters;
}

export function hexToRGBA(hex, alpha) {
  const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

  const getChunksFromString = (st, chunkSize) =>
    st.match(new RegExp(`.{${chunkSize}}`, "g"));

  const convertHexUnitTo256 = (hexStr) =>
    parseInt(hexStr.repeat(2 / hexStr.length), 16);

  const getAlphafloat = (a, alpha) => {
    if (typeof a !== "undefined") {
      return a / 255;
    }
    if (typeof alpha != "number" || alpha < 0 || alpha > 1) {
      return 1;
    }
    return alpha;
  };
  if (!isValidHex(hex)) {
    return { error: "Invalid HEX" };
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
}

/**
 * Returns a Array of Objetcs with property modified
 * @param {Array} list
 * @returns {Array}
 */
export function transformPropertyToLowerCase(list) {
  let base;
  return list.map((obj) => {
    base = {};
    for (const property in obj) {
      let fieldName = transformToLowerCase(property);
      base = { ...base, [fieldName]: obj[property] };
    }
    return base;
  });
}

/**
 * Transforms the strings
 * @param {String} str
 */
export function transformToLowerCase(str) {
  return `${str}`
    .split("")
    .map((letter) => (letter === " " ? "_" : letter.toLocaleLowerCase()))
    .join("");
}

/**
 * Transform the imported data to fleks data model
 * @param {any[]} list
 * @param {Array} importParserConfig
 */
export function traduceColumns(list, importParserConfig) {
  return list.map((item) => {
    const importedItemKeys = Object.keys(item);

    return Object.keys(importParserConfig).reduce(
      (acc, key) => {
        const optionalNames = importParserConfig[key].names;
        const usedName = optionalNames.find((name) =>
          importedItemKeys.includes(name)
        );

        if (usedName && key !== usedName) {
          acc[key] = acc[usedName];
          delete acc[usedName];
        }

        return acc;
      },
      { ...item }
    );
  });
}

/**
 * Converts the excel file to Array with Object data
 * @param {File} file
 * @returns {Promise}
 */
export function readDocumentExcel(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const workbook = XLSX.read(bufferArray, {
        type: "binary",
        cellDates: true,
        cellText: false,
      });
      const wsname = workbook.SheetNames[0]; // Name of the first Sheet
      const ws = workbook.Sheets[wsname]; // Read the sheet
      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false,
        dateNF: "dd-mm-yyyy hh:mm",
      });

      data.map((dat) => {
        for (let row in dat) {
          if (dat[row] === "") {
            delete dat[row];
          }
        }
      });
      const dataFormatted = data.filter((dat) => Object.keys(dat).length !== 0);
      resolve(dataFormatted);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
/**
 * Check if a date in string format is close to the next day
 * This function is only useful for the problem when importing dates from an excel file with the SheetJs library,
 * when some dates have one day subtracted
 */
export function isADateClosetoTheNextDay(dateString) {
  const verifiyHoursInDate = dateString.split(" ")[1];
  const exp = /^00:00$/;
  if (verifiyHoursInDate === undefined) {
    return false;
  } else {
    return exp.test(verifiyHoursInDate) ? false : true;
  }
}

export function convertDateToString(date) {
  const language = useSelector((state) => state.data?.language);
  const dateString =
    date &&
    moment(date, "DD-MM-YYYY")
      .locale(language || "en", [momentNl])
      .format("dddd, D MMMM YYYY");
  return dateString?.charAt(0).toUpperCase() + dateString?.slice(1);
}

export function calculateDuration(notificationDescription) {
  let duration = 7;
  if (notificationDescription) {
    if (notificationDescription.length > 300) {
      duration = Math.round((notificationDescription.length * 60) / 1500);
    }
  }

  return duration;
}

export function calculatePercentage(totalHours, currentHours) {
  const percent = (currentHours * 100) / totalHours;
  return Math.round(percent);
}

export function getLastDayMonth() {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const lastDay = new Date(y, m + 1, 0);
  const currentMonth = moment().format("MM");
  return lastDay.getDate() + "-" + currentMonth + "-" + y;
}

export function getFileExtension(string) {
  const extensionSplit = string.split(".");
  const extension = extensionSplit[extensionSplit.length - 1];
  return extension;
}

export function getFileName(string) {
  const fileNameSplit = string.split("/");
  const fileName = fileNameSplit[fileNameSplit.length - 1];
  return fileName;
}

export const fileLimitErrorMessage = {
  en: "The maximum number of files to upload is 5",
  nl: "Het maximaal aantal documenten dat geüpload kan worden is 5",
  de: "Es können maximal 5 Dateien hochgeladen werden",
};

export function formatMinutesToHM(totalMinutes, normalizeFormat = false) {
  let minutes = totalMinutes % 60;
  let hours = (totalMinutes - minutes) / 60;
  minutes = ~~minutes;
  if (minutes === 0 && hours === 0) {
    if (normalizeFormat) {
      return "0:00";
    } else {
      return "-";
    }
  } else {
    if (normalizeFormat) {
      const extraDigit = minutes.toString();
      if (extraDigit.length === 1) {
        return hours + ":0" + minutes;
      } else {
        return hours + ":" + minutes;
      }
    } else {
      return hours + "u" + minutes + "m";
    }
  }
}

export function formatHoursToHM(totalHours, normalizeFormat = false) {
  const minutes = totalHours * 60;

  return formatMinutesToHM(minutes, normalizeFormat);
}

export function workflowNotificationMessage(language, errorMessage) {
  const message = {};
  switch (language) {
    case "nl":
      message.titleSuccess = "Details succesvol bijgewerkt";
      message.titleError = "Fout bij bijwerken van details";
      message.successBreakHours = "Pauze-uren succesvol geüpdatet";
    case "de":
      message.titleSuccess = "Details erfolgreich aktualisiert";
      message.titleError = "Fehler beim Aktualisieren der Details";
      message.successBreakHours = "Pausenzeiten erfolgreich aktualisiert";
    default:
      message.titleSuccess = "Details updated successfully";
      message.titleError = "Error updating the details";
      message.successBreakHours = "Break hours updated successfully";
  }

  if (errorMessage === "Job setting doesn't allow break hours update") {
    if (language === "nl") {
      message.descriptionError =
        "Taakinstelling staat geen update van pauzeuren toe";
    } else if (language === "de") {
      message.descriptionError =
        "Die Jobeinstellung lässt keine Aktualisierung der Pausenzeiten zu";
    } else {
      message.descriptionError = errorMessage;
    }
  } else if (errorMessage === "verkeerde input.") {
    if (language === "nl") {
      message.descriptionError = "Verkeerde input";
    } else if (language === "de") {
      message.descriptionError = "Ungültige Eingabe";
    } else {
      message.descriptionError = "Invalid input";
    }
  } else {
    if (language === "nl") {
      message.descriptionError = "Probeer het later opnieuw";
    } else if (language === "de") {
      message.descriptionError = "Bitte versuchen Sie es später erneut";
    } else {
      message.descriptionError = "Please try again later";
    }
  }
  return message;
}
export const breakRange = [...Array(97).keys()].map((i) => i * 5 + 0 + "min");

export const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
export function debounce(func, delay) {
  let timeoutId;
  return function(...args) {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export function getLoginCheckboxMessage(locale = "en") {
  if (locale === "en") {
    return "I hereby agree to the general terms of use and the code of conduct of promoters of MediaMarkt.";
  }
  if (locale === "nl") {
    return "Ik ga hierbij akkoord met de algemene gebruikersvoorwaarden en de gedragscode van promotoren van MediaMarkt.";
  }
  if (locale === "de") {
    return "Hiermit stimme ich den Allgemeinen Nutzungsbedingungen und dem Verhaltenskodex der Veranstalter von MediaMarkt zu.";
  }
}

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function formatDateToDDMMYYYY(dateAndTime, withHour = false) {
  if (typeof dateAndTime === "string") {
    return dateAndTime.split(" ")[0];
  } else {
    // Parse the input date and time string into a Date object
    const date = new Date(dateAndTime);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Combine the parts into DD-MM-YYYY format
    const formattedDate = `${day}-${month}-${year} ${withHour ? "23:59" : ""}`;

    return formattedDate;
  }
}

export function convertStringToDate(dateString) {
  const parts = dateString.split(" ");

  // Extract date parts (DD-MM-YYYY)
  const dateParts = parts[0].split("-");
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // JavaScript months are 0-based
  const year = parseInt(dateParts[2], 10);

  // Extract time parts (hh:mm)
  const timeParts = parts[1].split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  // Create a new Date object
  const dateObject = new Date(year, month, day, hours, minutes);

  return dateObject;
}

export function parseCustomDateString(dateString) {
  const parts = dateString.split(/[- :]/);

  let formattedDate = `${parts[1]}/${parts[0]}/${parts[2]} ${parts[3]}:${parts[4]}`;
  if (!parts[3] || !parts[4]) {
    formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
  }

  const parsedDate = new Date(formattedDate);

  return parsedDate;
}

export function isAfasPlannerUser(user) {
  if (user && user.data && user.data.tenant) {
    const externalConnections = user.data.tenant.external_connections;
    const roles = user.data.roles;
    if (
      externalConnections &&
      externalConnections.length > 0 &&
      roles &&
      roles.length > 0
    ) {
      for (const role of roles) {
        for (const connection of externalConnections) {
          if (connection.includes("afas") && role.includes("planner_user")) {
            return true;
          }
        }
      }
    }
  }
}

export function isNmbrsPlannerUser(user) {
  if (user) {
    const externalConnections = user.data.tenant.external_connections;
    const roles = user.data.roles;
    if (
      externalConnections &&
      externalConnections.length > 0 &&
      roles &&
      roles.length > 0
    ) {
      for (const role of roles) {
        for (const connection of externalConnections) {
          if (connection.includes("nmbrs") && role.includes("planner_user")) {
            return true;
          }
        }
      }
    }
  }
}
