import { useEffect, useRef } from "react";
import moment from "moment";
import React from "react";

class AmbiguityError extends Error {
  constructor(message) {
    super(message);
    this.name = "AmbiguityError";
  }
}

/**
 * Recursively find and match keys within an object.
 * @param {Object} obj - The object to search through.
 * @param {Array<String>} keys - The keys to match within the object.
 * @returns {Object} An object mapping matched keys to their values.
 */
function findAndMatchKeysInObject(obj, keys) {
  const matches = {};

  function findParams(currentObj) {
    if (!currentObj || typeof currentObj !== 'object') return;

    for (const key in currentObj) {
      if (keys.includes(key)) {
        matches[key] = currentObj[key];
      }

      // Recurse into nested objects
      if (typeof currentObj[key] === 'object') {
        findParams(currentObj[key]);
      }
    }
  }

  findParams(obj);
  return matches;
}

/**
 * Find an object in an array by UUID and then search for matching params within that object.
 * @param {Array<Object>} array - Array of objects to search through.
 * @param {String} uuid - UUID of the object to find.
 * @param {Array<String>} params - The keys to match within the found object.
 * @returns {Object} An object mapping matched keys to their values.
 */
function findAndMatchByUUID(array, uuid, params) {
  // Find the target object by UUID
  const targetObject = array.find(item => item.uuid === uuid);

  if (!targetObject) {
    console.warn(`No object found with uuid: ${uuid}`);
    return {};
  }

  // Use the new utility function to find and match keys within the object
  return findAndMatchKeysInObject(targetObject, params);
}

/**
 * Create refs for a set of state variables and update them whenever the state changes.
 * @param {Array} states - An array of state variables to track.
 * @returns {Array} - An array of refs corresponding to the provided states.
 */
// function useDynamicRefs(states) {
//   const refs = states.map(state => useRef(state));

//   useEffect(() => {
//     states.forEach((state, index) => {
//       refs[index].current = state;
//     });
//   }, [states, refs]);

//   return refs;
// }

function useDynamicRefs(states) {
  const refs = useRef(states.map(state => ({ current: state }))); 

  useEffect(() => {
    states.forEach((state, index) => {
      refs.current[index].current = state; 
    });
  }, [states]);

  return refs.current;
}

/**
 * Updates the cache with the new data after a successful update.
 * @param {Object} params - An object containing all necessary parameters.
 * @param {Array} params.queryKey - The query key used to retrieve the cache data.
 * @param {Object} params.queryClient - The React Query client.
 * @param {Object} params.updatedData - The data returned from the update request.
 * @param {String} params.uuid - The UUID of the item to update.
 * @param {Array<String>} [params.matchKeys] - Optional keys to match and update in the cached data.
 */
function updateQueryCache({
  queryKey,
  queryClient,
  updatedData,
  uuid,
  matchKeys = [],
}) {
  queryClient.setQueryData(queryKey, (oldData) => {
    const updatedResults = oldData.results.map((item) => {
      if (item.uuid === uuid) {
        console.log("Matching and updating item in cache:", uuid);

        // If matchKeys are provided, update only those keys, otherwise add new data directly
        const matchedData = matchKeys.length > 0
          ? findAndMatchKeysInObject(updatedData.results, matchKeys)
          : updatedData;

        return {
          ...item,
          ...matchedData,
          // Ensure 'isSentToCronJob' is set to 'false' if not already present
          isSentToCronJob: item.isSentToCronJob !== undefined ? item.isSentToCronJob : false,
        };
      }
      return item;
    });

    return {
      ...oldData,
      results: updatedResults,
    };
  });

  console.log("Cache update complete for:", queryKey);
}

/**
 * Handles updating the query cache for the provided UUIDs.
 * @param {Object} params - An object containing all necessary parameters.
 * @param {Array<String>} params.uuids - Array of UUIDs to update in the cache.
 * @param {Object} params.queryClient - The React Query client instance.
 * @param {String} params.queryKeyPrefix - The prefix of the query key (e.g., "singles", "shifts", etc.).
 */
function removeByUUIDSFromQueryCache({ uuids, queryClient, queryKeyPrefix }) {
  const matchingQueries = queryClient.getQueryCache().findAll({
    predicate: (query) => query.queryKey[0] === queryKeyPrefix,
  });

  console.log(`Matching queries found for ${queryKeyPrefix}:`, matchingQueries);

  matchingQueries.forEach((query) => {
    console.log("Working on query: ", query.queryKey);

    uuids.forEach((uuid) => {
      queryClient.setQueryData(query.queryKey, (oldData) => {
        if (!oldData || !oldData.results) return oldData;

        const updatedResults = oldData.results.filter((item) => item.uuid !== uuid);

        console.log(`Removed UUID ${uuid} from query: ${JSON.stringify(query.queryKey)}`);

        return {
          ...oldData,
          results: updatedResults,
        };
      });
    });
  });
}

/**
 * Handles updating the query cache for the provided UUIDs and updates specific fields dynamically.
 * @param {Object} params - An object containing all necessary parameters.
 * @param {Array<String>} params.uuids - Array of UUIDs to update in the cache.
 * @param {Object} params.queryClient - The React Query client instance.
 * @param {String} params.queryKeyPrefix - The prefix of the query key (e.g., "singles", "shifts", etc.).
 * @param {Object} params.updatedData - The new data to update in the cache (e.g., start_time, end_time).
 * @param {Array<String>} params.matchKeys - The keys to match and update in the cached data.
 * @param {Function} [params.transformerFN] - Optional transformer function for special key formatting (e.g., for dates).
 */
function updateByUUIDSToQueryCache({
  uuids,
  queryClient,
  queryKeyPrefix,
  updatedData,
  matchKeys = [],
  transformerFN = null,
}) {

  const matchingQueries = queryClient.getQueryCache().findAll({
    predicate: (query) => query.queryKey[0] === queryKeyPrefix,
  });

  matchingQueries.forEach((query) => {
    queryClient.setQueryData(query.queryKey, (oldData) => {

      if (!oldData || !oldData.results) {
        console.warn("No data found for query key:", query.queryKey);
        return oldData;
      }

      const updatedResults = oldData.results.map((item) => {
        if (uuids.includes(item.uuid)) {

          const updatedItem = matchKeys.reduce((acc, key) => {
            let value = updatedData[key];

            // Apply optional transform function if provided
            if (transformerFN && typeof transformerFN === 'function') {
              value = transformerFN(key, value, acc[key]);
            }

            if (value !== undefined) {
              acc[key] = value;
            }

            return acc;
          }, { ...item });

          console.log("Updated item:", updatedItem);
          return updatedItem;
        }
        return item;
      });

      return {
        ...oldData,
        results: updatedResults,
      };
    });

    // LOGGER
    // const cacheAfterUpdate = queryClient.getQueryData(query.queryKey);
    // console.log("Cache AFTER update for UUIDs:", uuids, cacheAfterUpdate);
  });
}

// todo: place somewhere else
function dateTimeTransformer(key, newTime, originalDate) {

  if (key === 'start_date' || key === 'end_date') {
    const parsedOriginalDate = moment(originalDate, "DD-MM-YYYY HH:mm", true);

    if (!parsedOriginalDate.isValid()) {
      console.error("Invalid original date:", originalDate);
      return "Invalid date";
    }

    let timePart;

    if (moment(newTime, "HH:mm:ss", true).isValid()) {
      timePart = moment(newTime, "HH:mm:ss").format("HH:mm");
    } else if (moment(newTime, "HH:mm", true).isValid()) {
      timePart = moment(newTime, "HH:mm").format("HH:mm");
    } else {
      console.error("Invalid newTime:", newTime);
      timePart = parsedOriginalDate.format("HH:mm");
    }

    const transformedDate = `${parsedOriginalDate.format("DD-MM-YYYY")} ${timePart}`;

    return transformedDate;
  }

  return newTime;
}

export {
  // Main
  findAndMatchByUUID,
  useDynamicRefs,
  updateQueryCache,
  updateByUUIDSToQueryCache,
  removeByUUIDSFromQueryCache,
  // Helpers
  AmbiguityError,
  dateTimeTransformer,
};
