import {
  SHOW_PLANNING,
  SET_DATA_TO_PLANNING,
  SET_SELECTED_TAB,
  SET_SELECTED_PLANNING_JOB,
  SET_SELECTED_EMPLOYEES,
  SET_SELECTED_FULL_EMPLOYEES,
  SET_CURRENT_SORT,
  SET_CLUSTERS_ENABLED,
  SET_PREFERENCES_WITH_TEMP,
  USER_LOGIN,
  SET_SELECTED_PROPOSED,
  SET_SELECTED_FREELANCERS,
} from "redux/types";
import { combineReducers } from "redux";

const show = (state = false, action = {}) => {
  switch (action.type) {
    case SHOW_PLANNING:
      return action.data;

    default:
      return state;
  }
};

const dataList = (state = [], action = {}) => {
  switch (action.type) {
    case SHOW_PLANNING:
      return action.data ? state : [];

    case SET_DATA_TO_PLANNING:
      return action.data.list;

    default:
      return state;
  }
};

const totalApplicants = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATA_TO_PLANNING:
      return action.data.totalApplicants;

    default:
      return state;
  }
};

const totalInvitations = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATA_TO_PLANNING:
      return action.data.totalInvitations;

    default:
      return state;
  }
};

const totalPending = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATA_TO_PLANNING:
      return action.data.totalPending;

    default:
      return state;
  }
};

const areClusters = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATA_TO_PLANNING:
      return action.data.areClusters;

    default:
      return state;
  }
};

/**
 *
 * @param {"applicants" | "pending"} state
 * @param {{type: string, data: "applicants" | "pending"}} action
 * @returns {"applicants" | "pending"}
 */
const selectedTab = (state = "applicants", action) => {
  switch (action.type) {
    case SET_SELECTED_TAB:
      return action.data;

    case SHOW_PLANNING:
      return action.data ? state : "applicants";

    default:
      return state;
  }
};

const selectedJob = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_PLANNING_JOB:
      return action.data;

    case SET_SELECTED_TAB:
      return null;

    case SHOW_PLANNING:
      return action.data ? state : null;

    case SET_CLUSTERS_ENABLED:
      return null;

    default:
      return state;
  }
};

/**
 * List of selected employees' uuids
 * @param {string[]} state
 * @param {{type: string; data: string[] | boolean;}} action
 * @returns {string[]}
 */
const selectedEmployees = (state = [], action) => {
  switch (action.type) {
    case SET_SELECTED_EMPLOYEES:
      return Array.isArray(action.data) ? action.data : [];

    case SHOW_PLANNING:
      return action.data ? state : [];

    case SET_SELECTED_PLANNING_JOB:
      return [];

    default:
      return state;
  }
};

/**
 * List of selected proposed' uuids
 * @param {string[]} state
 * @param {{type: string; data: string[] | boolean;}} action
 * @returns {string[]}
 */
const selectedProposed = (state = [], action) => {
  switch (action.type) {
    case SET_SELECTED_PROPOSED:
      return Array.isArray(action.data) ? action.data : [];

    case SHOW_PLANNING:
      return action.data ? state : [];

    case SET_SELECTED_PLANNING_JOB:
      return [];

    default:
      return state;
  }
};

/**
 * List of selected proposed' uuids
 * @param {string[]} state
 * @param {{type: string; data: string[] | boolean;}} action
 * @returns {string[]}
 */
const selectedFreelancers = (state = [], action) => {
  switch (action.type) {
    case SET_SELECTED_FREELANCERS:
      return Array.isArray(action.data) ? action.data : [];

    case SHOW_PLANNING:
      return action.data ? state : [];

    case SET_SELECTED_PLANNING_JOB:
      return [];

    default:
      return state;
  }
};

/**
 * List of selected employees' full info
 * @param {string[]} state
 * @param {{type: string; data: string[] | boolean;}} action
 * @returns {string[]}
 */
const selectedFullEmployees = (state = [], action) => {
  switch (action.type) {
    case SET_SELECTED_FULL_EMPLOYEES:
      return Array.isArray(action.data) ? action.data : [];

    case SHOW_PLANNING:
      return action.data ? state : [];

    case SET_SELECTED_PLANNING_JOB:
      return [];

    default:
      return state;
  }
};

const currentSort = (state = "distance", action) => {
  switch (action.type) {
    case SET_CURRENT_SORT:
      return action.data;

    case SET_DATA_TO_PLANNING:
      return action.data.sort;

    case SHOW_PLANNING:
      return action.data ? state : "distance";

    case SET_CLUSTERS_ENABLED:
      return action.data ? "full_name" : state;

    default:
      return state;
  }
};

const clustersEnabled = (state = false, action) => {
  switch (action.type) {
    case SET_CLUSTERS_ENABLED:
      return action.data;

    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showClusterView !== "undefined"
        ? action.data.showClusterView
        : state;

    case USER_LOGIN:
      return state; // always show cluster configuration disabled
      // return action.data.preferences.planner &&
      //   typeof action.data.preferences.planner.showClusterView !== "undefined"
      //   ? action.data.preferences.planner.showClusterView
      //   : state;

    default:
      return state;
  }
};

const planning = combineReducers({
  show,
  dataList,
  totalApplicants,
  totalInvitations,
  totalPending,
  areClusters,
  selectedTab,
  selectedJob,
  selectedEmployees,
  selectedFullEmployees,
  currentSort,
  clustersEnabled,
  selectedProposed,
  selectedFreelancers,
});

export default planning;
