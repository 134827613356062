import {
  SET_CURRENT_DAY,
  SET_HEIGHT_JOB_SECTION,
  SET_WEEK_FILTER,
  SET_SHOW_HOURS_SECTION,
  RESET_FILTERS,
  RESET_FILTERS_PARTIAL,
  SET_LAST_APPLIED_EXTRA_FILTERS,
  SET_SHOW_CLUSTER_VIEW,
  SET_SHOW_FILTER,
  SET_TEMP_PREFERENCES,
  SET_PREFERENCES_WITH_TEMP,
  SET_SHOW_SIDEBAR,
  SET_SELECTED_JOB,
  SET_APPLICATIONS,
  SET_REGISTERED,
  SET_INVITATIONS,
  SET_PROPOSED,
  ADD_APPLICATION,
  ADD_REGISTERED,
  ADD_INVITATIONS,
  ADD_PROPOSED,
  SET_CANCELED_INVITATIONS,
  SET_CANCELED_APPLICATIONS,
  SET_CANCELED_PROPOSED,
  ADD_CANCELED_INVITATION,
  ADD_CANCELED_APPLICATION,
  ADD_CANCELED_PROPOSED,
  SET_SELECTED_CLUSTER,
  SET_PLANNER_CLUSTERS,
  SHOW_PLANNING,
  SET_SELECTED_TAB,
  SET_DATA_TO_PLANNING,
  SET_SELECTED_PLANNING_JOB,
  SET_CANCELED_REGISTERED,
  SET_PLANNER_SIDEBAR_LOADING,
  SET_PLANNER_SIDEBAR_RELOAD,
  SET_SELECTED_EMPLOYEES,
  SET_SELECTED_FULL_EMPLOYEES,
  SET_CURRENT_SORT,
  SHOW_NEW_JOB,
  SET_NEW_JOB,
  SET_STEP_ONE_COMPLETED,
  SET_CLUSTERS_ENABLED,
  SET_SELECTED_PROPOSED,
  SET_APPLIED_FREELANCERS,
  ADD_APPLIED_FREELANCER,
  SET_CANCELED_APPLIED_FREELANCERS,
  ADD_CANCELED_APPLIED_FREELANCER,
  SET_SELECTED_FREELANCERS,
  CHECK_IF_DATA,
} from "redux/types";
import { saveInLocalStorage } from "../../utils/helpers";
import { saveUserPreferences } from "./user";

/**
 * Change the current day
 * @param {Object} day current moment object
 */
export function setCurrentDay(day) {
  return { type: SET_CURRENT_DAY, day };
}

/**
 * Actions that set the height and width of job section
 * @param {Object} size { height, width }
 */
export function setJobSectionSize(size = {}) {
  saveInLocalStorage(size, "planner-job-section-size");

  return { type: SET_HEIGHT_JOB_SECTION, data: size };
}

/**
 * Action that trigger change of filters
 * @param {Object} filters
 */
export function setPlannerFilters(filters = {}) {
  return { type: SET_WEEK_FILTER, data: filters };
}

/**
 * Action that triggers changes over showFilters flag
 * @param {boolean} showFilters
 */
export function setShowFilters(showFilters = false) {
  return { type: SET_SHOW_FILTER, data: showFilters };
}

/**
 * Toggles the show hours section
 * @param {Boolean} show
 */
export function setShowHoursSection(show) {
  saveInLocalStorage(show, "planner-hour-section-show");

  return { type: SET_SHOW_HOURS_SECTION, data: show };
}

/**
 * Resets filters
 * @param {string[]} filtersToReset
 */
export function resetFilters(filtersToReset) {
  if (!filtersToReset) return { type: RESET_FILTERS };

  return { type: RESET_FILTERS_PARTIAL, data: filtersToReset };
}

export function setLastAppliedExtraFilters(newFilters) {
  return { type: SET_LAST_APPLIED_EXTRA_FILTERS, data: newFilters };
}

export function setShowClusterView(show) {
  return { type: SET_SHOW_CLUSTER_VIEW, data: show };
}

/** Preferences actions */

/**
 * Sets the preference(s) setting for the planner
 * @param {Object} preferences
 */
export function setTemporalPreferences(preferences) {
  return { type: SET_TEMP_PREFERENCES, data: preferences };
}

/**
 * Applies the real preferences with the temporal ones
 */
export function setPreferencesFromTemp() {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: SET_PREFERENCES_WITH_TEMP,
      data: state.planner.preferences.temporalPreferences,
    });
    dispatch({
      type: SET_TEMP_PREFERENCES,
      data: null,
    });

    dispatch(saveUserPreferences());
  };
}

export function setShowSidebar(show) {
  return { type: SET_SHOW_SIDEBAR, data: show };
}

export function setSelectedJob(job, { removeCluster = true } = {}) {
  return (dispatch) => {
    if (removeCluster) {
      dispatch({ type: SET_SELECTED_CLUSTER, data: null });
    }

    dispatch({ type: SET_SELECTED_JOB, data: job });
    dispatch({ type: SET_SHOW_SIDEBAR, data: true });
  };
}

export function setSelectedCluster(cluster) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_CLUSTER, data: cluster });
    dispatch({ type: SET_SELECTED_JOB, data: null });
    dispatch({ type: SET_SHOW_SIDEBAR, data: true });
  };
}

export function setApplications(applications) {
  return { type: SET_APPLICATIONS, data: applications };
}

export function setRegistered(registered) {
  return { type: SET_REGISTERED, data: registered };
}

export function setInvitations(invitations) {
  return { type: SET_INVITATIONS, data: invitations };
}

export function setProposed(proposed) {
  return { type: SET_PROPOSED, data: proposed };
}

export function setAppliedFreelancers(appliedFreelancers) {
  return { type: SET_APPLIED_FREELANCERS, data: appliedFreelancers };
}

export function addApplications(application) {
  return (dispatch, getState) => {
    const { sidebar } = getState().planner;

    dispatch({ type: ADD_APPLICATION, data: application });
    dispatch(updateCluster(sidebar.selectedJob, "scheduling"));
  };
}

export function addRegistered(registered) {
  return { type: ADD_REGISTERED, data: registered };
}

export function addInvitations(invitation) {
  return (dispatch, getState) => {
    const { sidebar } = getState().planner;

    dispatch({ type: ADD_INVITATIONS, data: invitation });
    dispatch(updateCluster(sidebar.selectedJob, "inviting"));
  };
}

export function addProposed(invitation) {
  return (dispatch, getState) => {
    const { sidebar } = getState().planner;

    dispatch({ type: ADD_PROPOSED, data: invitation });
    dispatch(updateCluster(sidebar.selectedJob || invitation.job, "proposing"));
  };
}

// FREELANCERS

export function addAppliedFreelancer(appliedFreelancer) {
  return (dispatch, getState) => {
    const { sidebar } = getState().planner;

    dispatch({ type: ADD_APPLIED_FREELANCER, data: appliedFreelancer });
    dispatch(
      updateCluster(sidebar.selectedJob || appliedFreelancer.job, "proposing")
    );
  };
}

/**
 * @param {{ uuid: string; }} application
 * @param {"cancelled_scheduling" | "cancelled_inviting" | "scheduling" | "cancelled_registered" | "cancelled_proposed"} type
 */
export function addEmployeeToSidebar(application, type = "scheduling") {
  return (dispatch, getState) => {
    const { sidebar } = getState().planner;
    const filterEmployee = (a) => a.uuid !== application.uuid;
    const newApplications = sidebar.applications.filter(filterEmployee);
    const newInvitations = sidebar.invitations.filter(filterEmployee);
    const newRegistered = sidebar.registered.filter(filterEmployee);
    const newProposed = sidebar.proposed.filter(filterEmployee);
    const newCanceledApplications = sidebar.canceledApplications.filter(
      filterEmployee
    );
    const newCanceledInvitations = sidebar.canceledInvitations.filter(
      filterEmployee
    );
    const newCanceledRegistered = sidebar.canceledRegistered.filter(
      filterEmployee
    );
    const newCanceledProposed = sidebar.canceledProposed.filter(filterEmployee);

    if (type === "cancelled_inviting") {
      newCanceledInvitations.push(application);
    } else if (type === "cancelled_scheduling") {
      newCanceledApplications.push(application);
    } else if (type === "scheduling") {
      newApplications.push(application);
    } else if (type === "cancelled_registered") {
      newCanceledRegistered.push(application);
    } else if (type === "cancelled_proposed") {
      newCanceledProposed.push(application);
    } else if (type === "proposed") {
      newProposed.push(application);
    }

    dispatch(updateCluster(sidebar.selectedJob, type));
    dispatch(setApplications(newApplications));
    dispatch(setRegistered(newRegistered));
    dispatch(setInvitations(newInvitations));
    dispatch(setProposed(newProposed));
    dispatch(
      setCanceledLists(
        newCanceledApplications,
        newCanceledInvitations,
        newCanceledRegistered,
        newCanceledProposed
      )
    );
  };
}

/**
 * @param {{ uuid: string; }} updatedJob
 * @param {"cancelled_scheduling" | "cancelled_inviting" | "scheduling" | "cancelled_registered" | "inviting" | "proposing" | "cancelled_proposed"} type
 */
export function updateCluster(updatedJob, type) {
  return (dispatch, getState) => {
    const { clusters } = getState().planner;
    const newClusters = clusters.map((cluster) => {
      cluster.jobs.forEach((job, index) => {
        if (job.uuid === updatedJob.uuid) {
          if (type === "cancelled_inviting") {
            cluster.jobs[index].invited_users--;
            cluster.total_invited_users--;
          } else if (type === "cancelled_scheduling") {
            cluster.jobs[index].accepted_users--;
            cluster.total_accepted_users--;
            cluster.pending_spots++;
          } else if (type === "scheduling") {
            cluster.jobs[index].accepted_users++;
            cluster.total_accepted_users++;
            cluster.pending_spots--;
          } else if (type === "cancelled_registered") {
            cluster.jobs[index].applied_users--;
            cluster.total_applied_users--;
          } else if (type === "inviting") {
            cluster.jobs[index].invited_users++;
            cluster.total_invited_users++;
          } else if (type === "proposing") {
            cluster.jobs[index].proposed_users++;
            cluster.total_proposed_users++;
          } else if (type === "cancelled_proposed") {
            cluster.jobs[index].proposed_users--;
            cluster.total_proposed_users--;
          }
        }
      });

      return cluster;
    });

    dispatch(setClusters(newClusters));
  };
}

export function setCanceledLists(
  applications,
  invitations,
  registered,
  proposed,
  appliedFreelancers
) {
  return (dispatch) => {
    dispatch({ type: SET_CANCELED_INVITATIONS, data: invitations });
    dispatch({ type: SET_CANCELED_APPLICATIONS, data: applications });
    dispatch({ type: SET_CANCELED_REGISTERED, data: registered });
    dispatch({ type: SET_CANCELED_PROPOSED, data: proposed });
    dispatch({
      type: SET_CANCELED_APPLIED_FREELANCERS,
      data: appliedFreelancers,
    });
  };
}

export function setPlannerSidebarLoading(isLoading) {
  return { type: SET_PLANNER_SIDEBAR_LOADING, data: isLoading };
}

export function addCanceledInvitation(invitation) {
  return { type: ADD_CANCELED_INVITATION, data: invitation };
}

export function addCanceledApplication(application) {
  return { type: ADD_CANCELED_APPLICATION, data: application };
}

export function addCanceledProposed(application) {
  return { type: ADD_CANCELED_PROPOSED, data: application };
}

export function addCanceledAppliedFreelancer(appliedFreelancers) {
  return { type: ADD_CANCELED_APPLIED_FREELANCER, data: appliedFreelancers };
}

export function setClusters(clusters) {
  return { type: SET_PLANNER_CLUSTERS, data: clusters };
}

export function setShowPlanning(show) {
  return {
    type: SHOW_PLANNING,
    data: show,
  };
}

export function setPlannerSidebarReload(isReloading) {
  return { type: SET_PLANNER_SIDEBAR_RELOAD, data: isReloading };
}

/**
 * @param {object[] | object} clusters
 * @param {boolean} areClusters
 */
export function setPlanningData(clusters, areClusters = true) {
  clusters = Array.isArray(clusters) ? clusters : [clusters];

  const jobList = clusters.reduce(
    (acc, current) => acc.concat(current.jobs),
    []
  );
  const totalApplicants = jobList.reduce(
    (acc, current) => acc + current.applied_users,
    0
  );
  const totalInvitations = jobList.reduce(
    (acc, current) => acc + current.invited_users,
    0
  );
  const totalPending = jobList.reduce(
    (acc, current) =>
      acc + current.number_of_employees - current.accepted_users,
    0
  );

  return (dispatch, getState) => {
    const {
      planner: { planning, preferences },
    } = getState();
    dispatch({
      type: SET_DATA_TO_PLANNING,
      data: {
        totalApplicants,
        areClusters,
        totalPending,
        totalInvitations,
        list: clusters,
        sort: preferences.sortCandidatesBy,
      },
    });

    if (totalApplicants === 0 && planning.selectedTab === "applicants") {
      dispatch({
        type: SET_SELECTED_TAB,
        data: "invitations",
      });
    } else if (totalApplicants > 0 && planning.selectedTab !== "applicants") {
      dispatch({
        type: SET_SELECTED_TAB,
        data: "applicants",
      });
    }

    dispatch({
      type: SHOW_PLANNING,
      data: true,
    });
  };
}

/**
 *
 * @param {"applicants" | "pending" | "invitations"} tab
 */
export function setSelectedTab(tab) {
  return {
    type: SET_SELECTED_TAB,
    data: tab,
  };
}

export function checkifData(data) {
  return {
    type: CHECK_IF_DATA,
    data
  };
}

export function setSelectedPlanningJob(job) {
  return {
    type: SET_SELECTED_PLANNING_JOB,
    data: job,
  };
}

/**
 * @param {string[]} selectedEmployees
 * @param {"proposed" | "employee"} type
 */
export function setSelectedEmployees(selectedEmployees, type = "employee") {
  return {
    type:
      type === "employee"
        ? SET_SELECTED_EMPLOYEES
        : type === "proposed"
        ? SET_SELECTED_PROPOSED
        : type === "freelancer"
        ? SET_SELECTED_FREELANCERS
        : null,
    data: selectedEmployees,
  };
}

/**
 * @param {any[]} selectedFullEmployees
 */
export function setSelectedFullEmployees(selectedFullEmployees) {
  return {
    type: SET_SELECTED_FULL_EMPLOYEES,
    data: selectedFullEmployees,
  };
}

export function setCurrentSort(sort) {
  return {
    type: SET_CURRENT_SORT,
    data: sort,
  };
}

export function setClustersEnabled(enabled) {
  return {
    type: SET_CLUSTERS_ENABLED,
    data: enabled,
  };
}

/********** New Job From Planner **********/
export function setShowNewJob(show) {
  return {
    type: SHOW_NEW_JOB,
    data: show,
  };
}

export function setNewJobData(job) {
  return {
    type: SET_NEW_JOB,
    data: job,
  };
}

export function setStepOneCompleted(completed) {
  return {
    type: SET_STEP_ONE_COMPLETED,
    data: completed,
  };
}
/********** New Job From Planner **********/
/**
 * @param {string} jobUuid job to edit
 * @param {{ [key: string]: any}} newData newData added to the job
 */
export function setJobOnPlanner(jobUuid, newData) {
  return (dispatch, getState) => {
    const { clusters } = getState().planner;
    const newClusters = clusters.map((cluster) => {
      for (let i = 0; i < cluster.jobs.length; i++) {
        const job = cluster.jobs[i];

        if (job.uuid === jobUuid) {
          cluster.jobs[i] = { ...cluster.jobs[i], ...newData };
          break;
        }
      }

      return cluster;
    });

    dispatch(setClusters(newClusters));
  };
}
