exports.serverConfig = {
  site: process.env.REACT_APP_SITE || "http://example.tk",
  wlabel: "example",
  host: process.env.REACT_APP_HOST || "example.tk",
  port: process.env.PORT || 3020,
  production: process.env.NODE_ENV === "production",
  apiUrl: process.env.REACT_APP_API_URL,
  marketplaceApiUrl: process.env.REACT_APP_MARKETPLACE_API_URL,
  recapchaSitekey: process.env.REACT_APP_RECAPCHA_SITE_KEY || "123456", // todo: this probably needs to go
  disableAutoOpenBrowser: true, // todo: check if this is valid in modern versions
};

exports.googleConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY || "as1j282j1k2j2",
};

exports.firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "firebase-asdasdas",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "asdasdasdasdasd",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "firebase.url",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "project-sasd",
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "project-sasd.appspot.com",
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "1231241",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:2asdi12112",
};

exports.firebasePushCertificate =
  process.env.REACT_APP_FIREBASE_PUSH_CERTIFICATE || "asdjm1Jss-sasdad";

exports.HOST = process.env.HOSTNAME || "";
exports.ENV = process.env.NODE_ENV || "development";
exports.GOOGLE_ANALYTICS_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "UA-826659-10";

exports.google = {
  clientID:
    process.env.REACT_APP_GOOGLE_CLIENT_ID || "21231231-google.user.content",
  clientSecret:
    process.env.REACT_APP_GOOGLE_CLIENT_SECRET || "AshYxYsHPsmsSj-YO",
  callbackURL: process.env.REACT_APP_GOOGLE_CALLBACK || "/auth/google/callback",
};

// todo: is this (google) truly secure?
exports.googleDriveFilePicker = {
  developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY || "asdashdasdad",
  // The Client ID obtained from the Google API Console. Replace with your own Client ID.
  clientId:
    process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID ||
    "sdasdashdkahsdkahsd.apps.googleusercontent.com",
  // See "Project number" under "IAM & Admin" > "Settings"
  appId: process.env.REACT_APP_GOOGLE_APP_ID || "12312124455",
};
exports.GIPHY_API_KEY =
  process.env.REACT_APP_GIPHY_API_KEY || "2l1h21j2kh312l3";

exports.isProduction = process.env.NODE_ENV === "production";
exports.isDebug = process.env.NODE_ENV === "development";

exports.intercomEnableTrackUsers =
  process.env.REACT_APP_INTERCOM_ENABLE_TRACK_USERS === "true" ||
  process.env.REACT_APP_INTERCOM_ENABLE_TRACK_USERS === "TRUE" ||
  process.env.REACT_APP_INTERCOM_ENABLE_TRACK_USERS === "True" ||
  false;

exports.intercomAppID = process.env.REACT_APP_INTERCOM_APP_ID || "xxxxxxxx";
